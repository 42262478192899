import React from 'react';
import PropTypes from 'prop-types';
import { EditProfilePadding, SingleTile, SettingsName, StyledInput } from '../../containers/Settings/styles';
import { ErrorMessage } from '../LoginPage/styles';
import { Error } from '../ForgetPassword/styles';
import {changeEmail} from "../../redux/actions/userProfileActions";
import {connect} from "react-redux";
import {isValidEmail} from '../../utils/methods'
import {withTranslation} from 'react-i18next';
import CommonButton from '../common/CommonButton/CommonButton';

class ChangeEmail extends React.Component {
  constructor() {
    super();
    this.state = {
      oldEmail: '',
      newEmail: '',
      confirmEmail:'',
      oldEmailMsg: false,
      passEmailMsg: false,
      showConfirmErrmsg: false,
      passMsg: false,
      showErrmsg: false,
      enableButton: false
    }
  }

  savePassword = (e) => {
    e.preventDefault();
    if(this.state.enableButton){
      let obj = {};
      obj['current_mail'] = this.state.oldEmail.trim();
      obj['new_mail'] = this.state.newEmail.trim();
      this.props.resetEmail(obj);
      this.setState({
        newEmail: '',
        oldEmail: '',
        confirmEmail: '',
        enableButton: false
      })
    }
  }
  oldEmailMessage = () => {
    if(this.state.oldEmail == ""){
      this.setState({
        oldEmailMsg: false,
        enableButton: false
      });
    }
    else if(!isValidEmail(this.state.oldEmail)) {
      this.setState({
        oldEmailMsg: true,
        enableButton: false
      })
    }
    else {
      this.setState({
        oldEmailMsg: false,
        enableButton: true
      })
    }
  }
  newEmailMessage = () => {

    if(!isValidEmail(this.state.newEmail)) {
      this.setState({
        passEmailMsg: true,
        enableButton: false
      })
    }
    else {
      this.setState({
        passEmailMsg: false
      },() => {
        this.confirmEmailMessage();
      })
    }
  }
  confirmEmailMessage = () => {
    if(this.state.confirmEmail != this.state.newEmail) {
      this.setState({
        showConfirmErrmsg: true,
        enableButton: false
      })
    }
    else {
      if (isValidEmail(this.state.newEmail)) {
        this.setState({
          passEmailMsg: false,
          enableButton: true,
          showConfirmErrmsg: false
        })
      }
    }
  }
  changeInputDetailsEmail = (stateName, event) => {
    this.setState({
      [stateName]: event.target.value
    },() => {
      if(stateName == 'newEmail') {
        this.newEmailMessage();
      }
      else if(stateName == 'oldEmail'){
        this.oldEmailMessage();
      }
      else if(stateName == 'confirmEmail') {
        this.confirmEmailMessage();
      }
    })
  }

  inputDetailsEmail = (fieldDetails, inputValue, stateName) => (
    <SingleTile width={fieldDetails.width} marginLeft={fieldDetails.marginLeft}>
      <SettingsName>
        {fieldDetails.name+': '}
        { fieldDetails.important
          ?
          <span className="important">*</span>
          :
          ''
        }
      </SettingsName>
      <StyledInput
        type="email"
        placeholder={fieldDetails.name}
        onChange={(event) => this.changeInputDetailsEmail(stateName, event)}
        value={ !inputValue ? '' : inputValue}
      />
    </SingleTile>
  );

  render() {
    const { oldEmail, enableButton, newEmail, confirmEmail, showConfirmErrmsg,passEmailMsg, oldEmailMsg} = this.state;
    const {t} = this.props;
    const showButtonDisabled = !enableButton || !oldEmail.trim();

    const oldEmailDetails = {
      name: t("Current Email"),
      width: 100,
      marginLeft: 0,
      important: true
    }

    const NewEmailDetails = {
      name: t("New Email"),
      width: 100,
      marginLeft: 0,
      important: true
    }

    const ConfirmEmailDetails = {
      name: t("Confirm Email"),
      width: 100,
      marginLeft: 0,
      important: true
    }
    return (
      <form onSubmit={this.savePassword}>
        <EditProfilePadding bottomBorder={false}>
          {this.inputDetailsEmail(oldEmailDetails,oldEmail,'oldEmail')}
          { oldEmailMsg
          &&
          <Error>
            <ErrorMessage>* Invalid Email </ErrorMessage>
          </Error>
          }
          {this.inputDetailsEmail(NewEmailDetails,newEmail,'newEmail')}
          { passEmailMsg
          &&
          <Error>
            <ErrorMessage>* Invalid Email </ErrorMessage>
          </Error>
          }
          {this.inputDetailsEmail(ConfirmEmailDetails,confirmEmail,'confirmEmail')}
          { showConfirmErrmsg
          &&
          <Error>
            <ErrorMessage>* New Email and Confirm Email should match</ErrorMessage>
          </Error>
          }
        </EditProfilePadding>
        <EditProfilePadding>
          {/* <SaveButton type="submit" addMargin disabled={showButtonDisabled}>
            {t('RESET')}
          </SaveButton> */}
          <CommonButton
            btnType={"rounded"}
            type="submit"
            addMargin
            disabled={showButtonDisabled}
            styles={{width:'158px', height:'35px', marginBottom:'55px'}}
            title={t('RESET')}
          />
        </EditProfilePadding>
      </form>
    )
  }
}

ChangeEmail.propTypes = {
  resetEmail: PropTypes.func.isRequired,
  t: PropTypes.func   
}
const mapDispatchToProps = (dispatch) => ({
  resetEmail: (resetData) => dispatch(changeEmail(resetData))
});

export default connect(null, mapDispatchToProps)(withTranslation()(ChangeEmail));