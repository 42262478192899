import React from 'react';
import PropTypes from 'prop-types';
import { EditProfilePadding, SingleTile, SettingsName, StyledInput } from '../../containers/Settings/styles';
import { ErrorMessage } from '../LoginPage/styles';
import { Error } from '../ForgetPassword/styles';
import { withTranslation } from 'react-i18next';
import CommonButton from '../common/CommonButton/CommonButton';

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      newEmail: '',
      confirmEmail: '',
      passEmailMsg: false,
      showConfirmErrmsg: false,
      passMsg: false,
      showErrmsg: false,
      enableButton: false
    }
  }

  savePassword = (e) => {
    e.preventDefault();
    if (this.state.enableButton) {
      let obj = {};
      obj['new_password'] = this.state.newPassword.trim();
      obj['old_password'] = this.state.oldPassword.trim();
      this.props.resetPassword(obj);
      this.setState({
        newPassword: '',
        oldPassword: '',
        confirmPassword: '',
        enableButton: false
      })
    }
  }
  newPasswordMessage = () => {
    const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if (!regex.test(this.state.newPassword)) {
      this.setState({
        passMsg: true,
        enableButton: false
      })
    }
    else {
      this.setState({
        passMsg: false
      }, () => {
        this.confirmPassMessage();
      })
    }
  }

  confirmPassMessage = () => {
    const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if (this.state.confirmPassword != this.state.newPassword) {
      this.setState({
        showErrmsg: true,
        enableButton: false
      })
    }
    else {
      if (regex.test(this.state.newPassword)) {
        this.setState({
          passMsg: false,
          enableButton: true,
          showErrmsg: false
        })
      }
    }
  }
  changeInputDetails = (stateName, event) => {
    this.setState({
      [stateName]: event.target.value
    }, () => {
      if (stateName == 'newPassword') {
        this.newPasswordMessage();
      }
      else if (stateName == 'confirmPassword') {
        this.confirmPassMessage();
      }
    })
  }
  inputDetails = (fieldDetails, inputValue, stateName) => {
    const { t } = this.props
    return (
      <SingleTile width={fieldDetails.width} marginLeft={fieldDetails.marginLeft}>
        <SettingsName>
          {t(fieldDetails.name) + ': '}
          {fieldDetails.important
            ?
            <span className="important">*</span>
            :
            ''
          }
        </SettingsName>
        <StyledInput
          type="password"
          placeholder={t(fieldDetails.name)}
          onChange={(event) => this.changeInputDetails(stateName, event)}
          value={!inputValue ? '' : t(inputValue)}
        />
      </SingleTile>
    )
  };

  render() {
    const { oldPassword, newPassword, confirmPassword, passMsg, showErrmsg, enableButton } = this.state;
    const { t } = this.props
    const showButtonDisabled = !enableButton || !oldPassword.trim();

    const oldPasswordDetails = {
      name: t("Current Password"),
      width: 100,
      marginLeft: 0,
      important: true
    }
    const newPasswordDetails = {
      name: t("New Password"),
      width: 100,
      marginLeft: 0,
      important: true
    }
    const confirmPasswordDetails = {
      name: t("Confirm Password"),
      width: 100,
      marginLeft: 0,
      important: true
    }



    return (
      <form onSubmit={this.savePassword}>
        <EditProfilePadding bottomBorder={false}>
          {this.inputDetails(oldPasswordDetails, oldPassword, 'oldPassword')}
          {this.inputDetails(newPasswordDetails, newPassword, 'newPassword')}
          {passMsg
            &&
            <Error>
              <ErrorMessage>* {t("Must be at least 8 characters and contain one uppercase, lowercase, and number")}</ErrorMessage>
            </Error>
          }
          {this.inputDetails(confirmPasswordDetails, confirmPassword, 'confirmPassword')}
          {showErrmsg
            &&
            <Error>
              <ErrorMessage>* {t("New password and Confirm password should match")}</ErrorMessage>
            </Error>
          }
        </EditProfilePadding>
        <EditProfilePadding>
          {/* <SaveButton type="submit" addMargin disabled={showButtonDisabled}>
            {t('RESET')}
          </SaveButton> */}
          <CommonButton
            btnType={"rounded"}
            type="submit"
            addMargin
            disabled={showButtonDisabled}
            styles={{width:'158px', height:'35px', marginBottom:'55px'}}
            title={t('RESET')}
          />
        </EditProfilePadding>
      </form>
    )
  }
}

ChangePassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  t: PropTypes.func
}

export default (withTranslation()(ChangePassword));